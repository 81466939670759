<!--
 * @Description:: 育小帮科技有限公司
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2022-11-04 16:43:33
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-31 13:44:40
 * @FilePath: /siana_admin/src/views/card_pin/components/list.vue
-->
<template>
  <div>
    <el-card shadow="never">
      <!-- 表格标题 -->
      <div
        class="tableHeader">
        <div class="left">
          卡密管理
        </div>
        <!--操作按钮-->
        <div class="right">
          <el-button
            type="primary"
            @click="$refs.show.open('add')">添加</el-button>
          <!-- <el-button
            type="primary"
            @click="exportTable">导出EXCEL</el-button> -->
          <ExportTable
            :data="tablesData" />
        </div>
      </div>
      <!--
      表格组件
      @setColumns  //获取表头方法
      @selectChange // 勾选方法
      @changePage  // 切换页码方法
     -->
      <Card v-if="phone"
        class="mt10"
        :columns="columns"
        :page-number="pageNumber"
        :page-size="pageSize"
        :total="total"
        :table-loading="tableLoading"
        :table-data="tableData"
        @edit="edit"
        @del="del"
        @changePage="changePage" />
      <Table v-if="!phone"
        class="mt10"
        :columns="columns"
        :page-number="pageNumber"
        :page-size="pageSize"
        :total="total"
        :table-loading="tableLoading"
        :table-data="tableData"
        @edit="edit"
        @del="del"
        @changePage="changePage" />
      <!--表格组件 end-->
      <ModalUpload ref="show"
        @ok="getData" />
    </el-card>
  </div>
</template>

<script>
// 引入组件及方法
// 引入组件
import Table from './table.vue'
import Card from './card.vue'
import ModalUpload from './modal.vue'
import ExportTable from '@/components/excel/index.vue'
import { exportExcel } from '@/utils/utils'
export default {
  name: 'Yxb30uiIndex',
  components: { Table, ModalUpload, Card, ExportTable },
  // 数据
  data() {
    return {
      phone: false,
      // 页码
      type: '',
      tablesData: [],
      pageNumber: 1, // 当前页
      // 页面尺寸
      pageSize: 10, // 每页显示条数
      // 当前页
      total: 0, // 数据总数
      // 表格数据
      tableData: [], // 列表数据
      // 表格数据 加载
      tableLoading: false, // 列表加载
      // 筛选项
      queryParams: {}, // 请求参数
      // 切换
      setColumnes: [], // 表头
      // 表头
      columns: [
        {
          // 序号
          title: '序号',
          fixed: 'left',
          minwidth: 50,
          key: 'index',
          align: 'center',
          // 工具提示
          tooltop: false
        },

        {
          // 年级
          title: '卡密',
          key: 'cardPin',
          minwidth: 200
          // end
        },
        {
          // 姓名
          title: '次数',
          key: 'number',
          minwidth: 130,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        {
          // 姓名
          title: '使用用户',
          key: 'userName',
          minwidth: 100,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        {
          // 姓名
          title: '是否使用',
          key: 'state',
          minwidth: 130,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        {
          // 层次
          title: '创建时间',
          key: 'createTime',
          minwidth: 180,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        {
          // 层次
          title: '操作',
          key: 'actions',
          minwidth: 100,
          fixed: 'right',
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        }
      ]
    }
  },
  //  初始化
  mounted() {
    this.phone = JSON.parse(window.localStorage.getItem('phone'))
    // 数据初始化
    this.getData()
  },

  methods: {
    // 获取数据
    getData() {
      this.$https('CARDPINLIST', {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }).then(res => {
        this.tableData = res.data.records
        this.total = parseInt(res.data.total)
      })
    },
    // 打开编辑弹窗
    edit(row) {
      console.log(row)
      this.$https('GPTKEYUPDETE', {
        id: row.id,
        state: row.state
      }).then(res => {
        if (res.status == 200) {
          this.$message.success('操作成功')
          this.getData()
        }
      })
    },
    // 删除项
    del(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$https('CARDPINDELETE', { ids: [row.id] }).then(res => {
          this.getData()
          this.$message.success('删除成功')
        })
      })
    },
    // 翻页
    changePage(data) {
      this.pageNumber = data.pageNumber
      this.pageSize = data.pageSize
      this.getData()
    },
    // 搜索
    search(data) {
      this.pageNumber = 1
      this.queryParams = data
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped></style>
