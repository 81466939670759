<!--
 * @Description:: 育小帮科技有限公司
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-02-14 10:32:02
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-31 11:32:58
 * @FilePath: /siana_admin/src/views/card_pin/components/modal.vue
-->
<template>
  <!--弹窗-->
  <el-dialog :title="title"
    :visible.sync="dialogVisible"
    width="500px"
    :fullscreen="phone"
    :close-on-click-modal="false"
    :before-close="handleClose"
    class="upload_box">
    <!--form-->
    <el-form ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      :label-position="position"
      label-width="100px">
      <el-form-item label="次数"
        prop="number">
        <!--下拉列表-->
        <el-input
          v-model="ruleForm.number"
          placeholder="请输入" />
      </el-form-item>
      <el-form-item label="批次"
        prop="batch">
        <!--下拉列表-->
        <el-input
          v-model="ruleForm.batch"
          placeholder="请输入" />
      </el-form-item>

    </el-form>
    <!-- 表单结束 -->
    <!--操作按钮-->
    <span slot="footer"
      class="dialog-footer">
      <el-button
        @click="handleClose">取
        消</el-button>
      <!--确定-->
      <el-button
        type="primary"
        :disabled="loading"
        @click="handleSubmit"><i
          v-if="loading"
          class="el-icon-loading"></i>确
        定</el-button>
    </span>
  </el-dialog>
  <!--弹窗 end-->
</template>

<script>
// 引入组件及方法
export default {
  name: 'Yxb3NewModal',

  data() {
    return {
      phone: false,
      type: 'state',
      // checked: false, //是否覆盖数据
      loading: false, // loading状态
      dialogVisible: false, // 弹窗显隐状态
      // 标题
      position: 'right',
      title: '', // 弹窗标题
      // 表单
      ruleForm: {
        number: '',
        batch: ''
      },
      // 校验规则
      rules: {
        number: [{ required: true, message: '请输入', trigger: 'change' }],
        batch: [{ required: true, message: '请输入', trigger: 'change' }]
      }
    }
  },

  //  初始化
  mounted() {
    this.phone = JSON.parse(window.localStorage.getItem('phone'))
    this.position = this.phone == true ? 'top' : 'right'
  },

  methods: {
    // 弹窗信息设置
    open(page, data) {
      this.phone = JSON.parse(window.localStorage.getItem('phone'))
      this.dialogVisible = true
      if (page == 'add') {
        this.title = '添加'
      } else {
        this.title = '编辑'
        this.ruleForm = data
      }
    },
    getApi(api) {
      this.$https(api, this.ruleForm).then(res => {
        this.$message.success(this.title + '成功')
        this.handleClose()
        this.$emit('ok')
      })
    },
    handleClose() {
      this.ruleForm = {
        number: '',
        batch: 0
      }
      this.dialogVisible = false
    },
    // 提交
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.title == '添加') {
            this.getApi('CARDPINADD')
          }
        }
      })
    }
  }
}
</script>
<style lang="scss">
.upload_box {
  .el-upload-dragger {
    width: 460px !important;
  }
}
</style>
