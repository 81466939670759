<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-05-31 13:29:32
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-31 13:47:33
 * @FilePath: /siana_admin/src/components/excel/index.vue
-->
<template>
  <download-excel
    class="export-excel-wrapper"
    :data="DetailsForm"
    :fields="json_fields"
    name="卡密.xls">
    <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
    <el-button type="success"
      @click="$message.success('正在下载...')">导出</el-button>
  </download-excel>

</template>

<script>
export default {
  data() {
    return {
      json_fields: {
        序号: 'index',
        卡密: 'name'
      },
      DetailsForm: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$https('CARDPINALLLIST', {}).then(res => {
        if (res.status == 200) {
          this.DetailsForm = []
          res.data.map((item, index) => {
            const obj = {
              index: index + 1,
              name: item
            }
            this.DetailsForm.push(obj)
          })
        }
      })
    }
  }
}
</script>

<style>
.export-excel-wrapper {
  display: inline-block;
  margin-left: 20px;
}
</style>
