<!--
 * @Description: 请修改成厚
 * @Version: 1.0
 * @Author: lvzhou
 * @Date: 2019-10-16 21:27:48
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-31 11:30:43
 -->
<template>
  <!--表格-->
  <div class="table_box">
    <!--表格配置-->
    <el-table
      v-loading="tableLoading"
      stripe :data="tableData"
      style="width: 100%"
      :header-cell-style="{
        background: '#EEF1F6',
        color: 'rgba(41,44,53,0.7)',
      }">
      <!--表格配置 end-->
      <template slot="empty">
        <div
          class="v2-table__empty-data v2-table__empty-border"
          style="width: 100%">
          <!--无数据显示-->
          <div
            class="v2-table__empty-default">
            <el-empty
              description="暂无数据" />
          </div>
        </div>
      </template>
      <!--循环条目配置-->
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        :fixed="item.fixed"
        :prop="item.key"
        :label="item.title"
        :min-width="item.minwidth"
        :sortable="item.sortable"
        :filters="item.filters"
        :align="item.align"
        :filter-method="item.filters && filterHandler"
        :show-overflow-tooltip="item.tooltop">
        <!-- 结束 -->
        <!--操作-->
        <template
          slot-scope="scope">
          <template
            v-if="item.key == 'actions'">
            <!--按钮配置-->
            <!-- <el-button
              type="primary"
              size="mini"
              @click="$emit('edit',scope.row)">编辑</el-button> -->
            <el-button
              type="danger"
              size="mini"
              @click="$emit('del',scope.row)">删除</el-button>
            <!--按钮配置 end-->
          </template>
          <!--序号-->
          <template
            v-else-if="item.key == 'index'">
            {{ scope.$index + 1 }}
          </template>

          <template
            v-else-if="item.key == 'state'">
            <span
              v-if="scope.row.state=='0'">未使用</span>
            <span
              v-if="scope.row.state=='1'">使用</span>
          </template>
          <template
            v-else>{{ scope.row[item.key] }}</template>
        </template>
      </el-table-column>
    </el-table>
    <!--分页配置-->
    <div
      style="margin-top: 20px; height: 40px;text-align: right;">
      <el-pagination
        class="fr"
        :current-page="pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange" />
    </div>
    <!--分页end-->
  </div>
</template>

<script>
// 引入组件及方法
import { mapGetters } from 'vuex'
export default {
  props: {
    // 表格数据
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 表头项
    columns: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 加载状态
    tableLoading: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    tableClass: { type: String, default: '' }, // 表格样式
    pageSize: { type: Number, default: 10 }, // 当前页
    pageNumber: { type: Number, default: 1 }, // 每页显示条数
    total: { type: Number, default: 0 }, // 总数据
    isSelection: { type: Boolean, default: false } // 选中项
  },
  // 数据
  data() {
    return {
      // 表头
    }
  },

  methods: {
    // 过滤表头
    filterHandler(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    // 修改翻页
    handleSizeChange(e) {
      this.$emit('changePage', {
        pageNumber: this.pageNumber,
        pageSize: e
      })
    },
    // 设置每页条数
    handleCurrentChange(e) {
      this.$emit('changePage', {
        pageNumber: e,
        pageSize: this.pageSize
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.table_box {
  position: relative;
  .table_icon {
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 4px;
    background: #dde2e8;
    text-align: center;
    line-height: 22px;
    z-index: 999;
    right: 20px;
    top: 15px;
    cursor: pointer;
  }
}
</style>
